<template>
  <div id="attachments_library">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle">
            3D библиотека <br />
            аттачментов
          </h1>
          <button
            type="button"
            class="btnAbout"
            @click="isAbout = !isAbout"
            v-html="!isAbout ? 'Читать о библиотеке' : '< Вернуться'"
          ></button>
        </div>
      </div>
    </div>
    <div class="attachments_library_view" v-if="!isAbout">
      <div class="attachments_library_view-goBack">
        <router-link :to="{ name: 'library3d' }">
          <icon name="chevron-left" scale="1.8" class="mr-50"></icon>
          <span>Вернуться к 3D библиотекам</span>
        </router-link>
      </div>
      <div class="attachments_library_view_wrapper">
        <router-link
          :to="{ name: 'attachmentsLibraryItem', params: { styleId: style._id } }"
          v-for="style in stylesList"
          :key="style.id"
          class="toothStyle"
        >
          <img :src="style.logo" alt="" />
        </router-link>
      </div>
      <infinite-loading @infinite="getStylesList">
        <span slot="no-more">
          <span v-if="!isLoggedIn && !isPremium">
            Для получения возможности скачивания всех библиотек в формате psd, pptx, stl и eoff Вам необходимо <br />
            <router-link :to="{ name: 'signin' }">зарегистрироваться</router-link> и приобрести премиум аккаунт
          </span>
          <span v-if="isLoggedIn && !isPremium">
            Для скачивания всех библиотек в формате psd, pptx, stl и eoff Вам необходимо приобрести
            <router-link :to="{ name: 'profile' }">премиум аккаунт</router-link>
          </span>
        </span>
      </infinite-loading>
    </div>
    <div id="attachments_library_about" v-if="isAbout">
      <section id="attachments_library_section_1">
        <div class="wrapper">
          <div class="attachmentsDeisgn">
            <p class="attachmentsDeisgn-about">
              3D Аттачменты для создания съемных конструкций необходимы при проектировании протезов в 3D программах.
              <br />
              Аттачменты представлены в комплекте со втулками, что позволяет создать полноценную конструкцию.
            </p>
            <img src="./../../assets/images/library3d/attachmentsDesign.jpg" alt="" class="attachmentsDeisgn-img" />
          </div>
        </div>
      </section>
      <section id="attachments_library_section_2">
        <img
          src="./../../assets/images/library3d/attachmentsDesignPreview.jpg"
          alt=" "
          class="attachmentsDesignPreview-img"
        />
      </section>
      <section id="attachments_library_section_3">
        <div class="wrapper">
          <div class="demoVideoContent">
            <div class="demoVideoContent-container">
              <div class="demoVideoContent-container-content">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/mi4SWlDOEow"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";

import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "AttachmentsLibrary",
  components: { mainMenu, mainFooter, InfiniteLoading },

  data() {
    return {
      isPremium: false,
      isAbout: false,
      page: 1,
      endpoint: "attachments_library/",
      endpointTemplate: "attachments_library_template/",
      stylesList: [],

      fileDownload: {
        startTemlatePPTX: "",
        startTemlatePPTXPSD: "",
      },
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    getProfile() {
      this.$http.post(this.profileStatusEndpoint).then((response) => {
        if (response.data.isPremium) {
          this.isPremium = true;
        } else {
          this.isPremium = false;
        }
      });
    },
    getFileDownload() {
      this.$http.get(this.endpointTemplate).then((response) => {
        if (response.data.success) {
          this.fileDownload.startTemlatePPTX = response.data.links.startTemlatePPTX;
          this.fileDownload.startTemlatePPTXPSD = response.data.links.startTemlatePPTXPSD;
        }
      });
    },
    getStylesList($state) {
      var options = {
        params: { page: this.page },
      };
      this.$http.get(this.endpoint, options).then((response) => {
        // console.log(response);
        if (response.data.attachmentsLibrarysList.length) {
          this.stylesList = this.stylesList.concat(response.data.attachmentsLibrarysList);
          this.page++;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  },
  created() {
    this.getProfile();
    this.getFileDownload();
  },
};
</script>

<style lang="sass">
#attachments_library
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	& .headerPreview
		display: flex
		min-height: 360px
		align-items: center
		padding: 0 2rem
		background: url('./../../assets/images/library3d/attachmentsBG.jpg')
		background-size: cover
		background-position: center center
		& .sectionInfo
			width: fit-content
			@media screen and (max-width: 426px)
				display: flex
				flex-direction: column
				align-items: center
				width: 100%
				text-align: center
			& .sectionTitle
				font-weight: normal
				color: #fff
				padding-bottom: 1.5rem
				margin: 0 0 1.5rem
				border-bottom: 1px solid #fff
			& .sectionDescription
				color: #fff
				font-size: 1.6rem
				margin: 0 0 1.5rem
			& .btnAbout
				display: block
				width: 100%
				background: #ff00c0
				color: #fff
				padding: 0.5rem 2rem
				border: 1px solid transparent
				margin-bottom: 1rem
				cursor: pointer
			& .btnShareLink
				display: block
				width: 100%
				background: #2c7802
				color: #fff
				padding: 0.5rem 2rem
				border: 1px solid transparent
				cursor: pointer
				transition: all 0.5s
				&:hover
					background: #579933
			& .dynamicSrcBar
				display: flex
				height: 36px
				& input
					display: block
					width: 100%
					border: 1px solid #333
					background: #333
					color: #fff
				& .btnModalWindow
					display: block
					border: 1px solid transparent
					color: #ffffff
					background-color: #2c7802
					padding: 0 0.25rem
					cursor: pointer
					transition: all 0.5s
					&:hover
						background: #579933

	.attachments_library_view
		background: #111111
		padding-bottom: 2rem
		min-height: 50vh
		& .attachments_library_view-goBack
			margin: 0.5rem auto
			max-width: 1024px
			width: 100%
			& a
				display: inline-flex
				align-items: center
				padding: 0.5rem 0
				font-size: 18px
				color: #a0a0a0
				transition: all 0.5s
				&:hover
					color: #e0e0e0
		.attachments_library_view_wrapper
			display: block
			max-width: 1024px
			width: 100%
			margin: 0 auto
			.toothStyle
				display: inline-block
				width: calc(100% / 5)
				border: 2px solid #111
				margin-bottom: -4px
				@media screen and (max-width: 769px)
					width: calc(100% / 4)
				@media screen and (max-width: 600px)
					width: calc(100% / 3)
				@media screen and (max-width: 426px)
					width: calc(100% / 2)
				&:hover
					border: 2px solid #444

				& img
					display: block
					width: 100%
					height: auto

#attachments_library_about
	font-family: 'Roboto'
	letter-spacing: 0rem
	color: #ffffff

#attachments_library_section_1
	padding: 2rem 1rem
	background-color: #000
	& .wrapper
		flex-direction: row
		@media screen and (max-width: 768px)
			flex-direction: column
		& .attachmentsDeisgn
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center
			padding: 0 2rem
			& .attachmentsDeisgn-about
				font-family: 'Ropa Sans Pro SC Regular'
				text-align: center
				font-size: 1.3rem
				margin: 1rem 0 0.5rem
			& .attachmentsDeisgn-img
				display: block
				width: 100%
				max-width: 850px
				object-fit: contain

#attachments_library_section_2
	& .attachmentsDesignPreview-img
		display: block
		margin: 0 auto
		width: 100%
		object-fit: cover
		min-height: 310px
		background: #ededef

#attachments_library_section_3
	padding: 2rem 1rem 4rem
	background-color: #000000
	& .demoVideoContent
		display: flex
		flex-direction: column
		align-content: center
		justify-content: center
		& .demoVideoContent-container
			display: block
			box-sizing: borderbox
			& .demoVideoContent-container-title
				font-size: 1.8rem
				font-weight: 700
				text-align: center
				padding: 2.5rem 1rem
			& .demoVideoContent-container-content
				display: block
				position: relative
				padding-bottom: 56.20% //16:9
				padding-top: 25px
				height: 0
				border: 1px solid transparent
				& iframe
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
</style>
